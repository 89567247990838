@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

.container {
  --crossFadeDuration: 2s;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity var(--crossFadeDuration) ease-in-out;
  filter: blur(2.6px);
  transform: scale(1.1);
}

.fadeOut {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
}

.gradientPurple { background: linear-gradient(to bottom right, #9333ea, #3b82f6); }
.gradientBlue { background: linear-gradient(to bottom right, #1e3a8a, #3b82f6); }
.gradientBrown { background: linear-gradient(to bottom right, #78350f, #b45309); }
.gradientGreen { background: linear-gradient(to bottom right, #065f46, #34d399); }
.gradientYellow { background: linear-gradient(to bottom right, #92400e, #fbbf24); }

.content {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 33.33vh;
  transform: translateY(-50%);
  width: 100%;
}

.logo {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.siteName {
  font-family: 'Audiowide', cursive;
  font-size: 4.5rem;
  text-align: center;
  color: #E0E0E0;
  margin: 0;
  padding: 20px 0;
  letter-spacing: 2px;
  line-height: 1.2;
  text-shadow: 
    0 0 10px rgba(224, 224, 224, 0.8),
    0 0 20px rgba(224, 224, 224, 0.5),
    0 0 30px rgba(224, 224, 224, 0.3);
  cursor: default;
  perspective: 1000px;
}

.siteNameWord {
  display: block;
  margin-bottom: 10px;
}

.siteNameLetter {
  display: inline-block;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
  will-change: transform;
}

.siteName:hover .siteNameLetter {
  animation: letterHover 1s ease-in-out infinite alternate;
}

@keyframes letterHover {
  0% {
    color: #E0E0E0;
    text-shadow: 
      0 0 10px rgba(224, 224, 224, 0.8),
      0 0 20px rgba(224, 224, 224, 0.5),
      0 0 30px rgba(224, 224, 224, 0.3);
    transform: translateY(0) scale(1);
  }
  100% {
    color: #A020F0;
    text-shadow: 
      0 0 10px rgba(138, 43, 226, 0.8),
      0 0 20px rgba(138, 43, 226, 0.5),
      0 0 30px rgba(138, 43, 226, 0.3);
    transform: translateY(-5px) scale(1.1);
  }
}

.subtitle {
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 66.66vh;
  transform: translateY(-50%);
}

.contentShape {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.25rem;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.contentInner {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.contentVisible {
  opacity: 1;
}

.homeLink {
  margin-top: 1rem;
}

.homeLink a {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: white;
}

.homeLink a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.homeLink a span {
  margin-left: 0.5rem;
  font-size: 1rem;
}

.counters {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Orbitron', sans-serif;
  font-size: 1rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.counter, .transitionCounter {
  margin-bottom: 5px;
}

.transitionCounter {
  font-size: 0.9rem;
  color: #FFD700; /* Gold color to differentiate */
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

.videoStatus, .activeVideo {
  font-size: 0.8rem;
  color: #FFD700;
  margin-top: 5px;
}

.activeVideo {
  font-weight: bold;
}

.playing {
  color: #00FF00; /* Green color for playing state */
}

.paused {
  color: #FF0000; /* Red color for paused state */
}


@media (max-width: 767px) {
  .container {
    font-size: 14px;
  }

  .siteName {
    margin-top: -2.6rem;
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }

  .counters {
    font-size: 0.8rem;
  }

  .contentContainer {
    width: 89vw; /* Set width to 89% of viewport width for mobile */
  }

  .contentShape {
    width: 78%; /* Adjust the inner content to fill the container */
    padding: 1.5rem; /* Slightly reduce padding for mobile */
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .siteName {
    margin-top: 0.1rem;
    font-size: 1.78rem; /* Adjust font size for landscape */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  }

  .logoContainer {
    top: 8.9vh; /* Position at 1/3 of the screen height */
    transform: none; /* Remove vertical centering */
  }

  .contentContainer {
    top: 50%; /* Center vertically */
  }

  .contentShape {
    width: 100%; /* Adjust the inner content to fill the container */
    padding: 1.5rem; /* Slightly reduce padding for mobile */
  }

}

